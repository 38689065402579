import React from 'react'

const Para=[{
  para:"Innate immunity is not constrained by the need to select antigen-specific lymphocytes, instead employing ubiquitously and constitutively expressed pathogen recognition receptors (PRRs), such as Toll-like receptors (TLRs). TLRs activate innate immunity by binding pathogen-associated molecular patterns (PAMPs). TLRs on the cell surface (TLR1/2, TLR2/6, TLR4, TLR5 and TLR10) are constantly exposed to extracellular stimuli and regulate immune activation by maintaining quiescence during exposure to innocuous environmental molecules and commensal microbes. The capacity for cell surface TLRs to differentiate ‘background environmental and commensal microbial noise’ from PAMPs is critical for homeostasis. Activation of intracellular TLRs typically induces a potent immune response mediated by type I interferons (IFNs) and inflammatory cytokines. When delayed and over-exuberant, this inflammatory response can contribute to severe respiratory viral illnesses such as COVID-19 and avian influenza."
},
{
  para:" TLR2 was initially identified as a pathogen receptor for bacterial cell-wall components (e.g. peptidoglycan), but is now recognized to be activated by virus components. TLR2 localizes to the plasma membrane and forms heterodimers with TLR1 or TLR6, to recognize a broad repertoire of PAMPs via a common signaling pathway regardless of the specific TLR2 heterodimer activated and inducing similar gene expression signatures. TLR2 has more recently been identified as involved in innate immune activation to a diverse range of viruses."
}]

const Para2=[
{
  para:" The envelope protein of β-coronaviruses, including SARS-CoV-2, is also sensed by TLR2. In this context, it has been suggested that TLR2-mediated inflammation contributes to cytokine storm-induced mortality in COVID-19 patients. (Girkin et al. 2022)."
},
{
  para:" TLR2 and MYD88 expression have been associated with COVID-19 disease severity. Mechanistically, TLR2 and MyD88 were required for β-coronavirus–induced inflammatory responses, and TLR2-dependent signaling induced the production of pro-inflammatory cytokines during coronavirus infection independent of viral entry. TLR2 sensed the SARS-CoV-2 envelope protein as its ligand. Additionally, blocking TLR2 signaling in vivo provided protection against the pathogenesis of SARS-CoV-2 infection."
}
]

const Para3=[{
  para:"Absolute RNA counts of MYD88 (A), TLR2 (D) in patients with mild-to-moderate (n = 11), severe (n = 10), and critical (n = 11) COVID-19, and 13 healthy controls. (Zheng M, et. al. 2021)"
},
{
  para:"A study has shown that TLR2 can sense and bind SARS-CoV-2 E protein and further induce the production of proinflammatory cytokines during coronavirus infection. Inhibition of TLR2 and its downstream signaling pathways in the body can prevent and control the occurrence of COVID-19."
},
{
  para:"Zheng and colleagues used public data sets to analyze the expression of TLRs and downstream adaptor proteins in COVID-19 patients with varying degrees of disease progression revealed that E protein can activate TLR2 and downstream immune signaling pathways. (Zhin-Mei L, et al. 2022) "
},
{
  para:"In addition, SARS-CoV-2 E protein can induce TLR2-related inflammatory responses in experimental group mice, while TLR2 inhibitors can protect mice from mortality caused by SARS-CoV-2 infection. These data indicate that TLR2 can recognize and bind SARS-CoV-2 E protein to activate downstream TLR2 signaling pathway and achieve antiviral immune response (Zheng et al., 2021). About a further research, recombinant SARS-CoV-2 S protein also activated the TLR2-mediated NF-κB pathway to activate inflammatory factors release in human immune cells. This result requires further experimental study (Jung and Lee, 2021).  "
},
{
  para:"Overexpression of TLR1/2 may promote the excessive cellular inflammatory response induced by SARS-CoV-2, hence small molecule inhibitors of TLR2 may be a potential agentia to prevent strong and excessive inflammatory response (Zheng et al., 2021). A study of experimental TLR2 inhibitor oxPAPC in mice expressing human ACE2 showed that oxPAPC significantly reduced the release of inflammatory cytokines as well chemokines even during the process of viral invasion (Zheng et al., 2021). Targeting TLR2 with antagonists significantly reduce death rates of SARS-CoV-2-infected individual compared with control-treated mice, suggesting that TLR2 probably participate in SARS-CoV-2-infected process. Further, this study has implications for the COVID-19treatment and prevention: applying TLR2 antagonists such as oxPAPC in patients with outbreaks of excessive inflammation may reduce the inflammatory response and possibly improve the cure rates of patients (Zheng et al., 2021)."
}
]

export default function Covid() {
  return (
    <div className='md:py-[30px]   
    px-[20px] py-[20px]
    sm:px-[80px] 2xl:py-[80px] font-openSans'>

          <h1 className='text-[36px] sm:text-[48px] font-openSans 2xl:text-6xl text-center font-bold'>TLR2 and COVID 19</h1>

          {Para.map((data,i)=>(
        <div className='xl:text-[24px] 2xl:text-3xl py-[30px] '>

          <p>{data.para}</p>

      </div>))}

      <div className='flex flex-col justify-center items-center'>
        <div className=' '>
          <img className='sm:w-[750px] sm:h-[539px]' src="./images/TLRCovid.svg" alt="" />
        </div>
        <div className='flex lg:px-[150px] xl:px-[270px] text-[#454343] text-[12px]  sm:mt-[-50px]  '>
          <p>Some TLRs may be involved in the recognition process of SARS-CoV-2. TLR2 on the cell surface mainly senses SARS-CoV-2 E protein, TLR4 mainly recognizes SARS-CoV-2 S protein. TLR3 recognizes dsRNA produced during SARS-CoV-2 replication, TLR7/8 senses ssRNA of SARS- CoV-2, and TLR9 recognizes CPG-containing sequences of the SARS-CoV-2 genome. These TLRs recognize SARS-CoV-2 and activate downstream signaling pathways, triggering downstream inflammatory factors to participate in the immune response. TLRs agonists are shown in green and TLRS antagonists are shown in red.</p>
        </div>

      </div>

      {Para2.map((data,i)=>(
        <div className='xl:text-[24px] 2xl:text-3xl py-[30px] '>

          <p>{data.para}</p>

      </div>))}

      <div className='grid sm:grid-cols-2'>

              <div className='flex justify-center'>
                <img className="" src="./images/Covid1.svg" alt="" />
              </div>

              <div className='flex justify-center'>
                <img src="./images/Covid2.svg" alt="" />
              </div>
      </div>
      {Para3.map((data,i)=>(
        <div className='xl:text-[24px] 2xl:text-3xl py-[30px] '>

          <p>{data.para}</p>

      </div>))}
     
      </div>
      
    
  )
}
