import React from 'react'
import { Link } from "react-router-dom";

const links=[
    {
        title:"MDS:",
        desc:"https://ashpublications.org/blood/article/132/Supplement%201/798/266200/A-Clinical-Study-of-Tomaralimab-OPN-305-a-Toll",
    },
    {
     title:"",
     desc:"https://www.sciencedirect.com/science/article/pii/S0006497119368478"   
    },
    {
     title:"Myocardial ischemia:",
     desc:"https://pubmed.ncbi.nlm.nih.gov/22354933/",
    },
    {
     title:"Tomaralimab NCI Thesaurus:",
     desc:"https://ncit.nci.nih.gov/ncitbrowser/pages/concept_details.jsf?dictionary=NCI_Thesaurus&version=23.06d&code=C120554&ns=ncit&type=properties&key=null&b=1&n=0&vse=null",
    },
    {
     title:"Treatment of delayed graft function post transplant:",
     desc:"https://cordis.europa.eu/project/id/261468",
    },
    {
     title:"Phase 1 study Healthy volunteers:",
     desc:"https://pubmed.ncbi.nlm.nih.gov/23880971/",
    }

]


export default function Published() {
  return (
    <div className='md:py-[30px]   
    px-[20px] py-[20px]
    sm:px-[80px] 2xl:py-[80px] font-openSans'>
      
      <h1 className='text-[36px] sm:text-[48px] font-openSans 2xl:text-6xl text-center font-bold'>Published Research</h1>

        <div className='mt-[40px]'>
        
        </div>
      {links.map((data,i)=>(
        <ol className='list-disc text-[16px] sm:text-[18px] md:text-[24px]  break-all px-[20px] xl:px-[80px] pb-5'>
            <li className=''>
                <div className=''>
                <span className='font-bold pr-2'>
                {data.title}
                </span>
                <Link to={data.desc} target='_blank' >
                    
                        {data.desc}
                    
                </Link>
                </div>
            </li>
        
        </ol>
      
        ))}

        
    </div>
  )
}







