import React from 'react'
const Para=[{
    para:"Dengue is a mosquito-borne viral disease transmitted through a human-to-mosquito-to human transmission cycle typically by the Aedes mosquitoes: Aedes aegypti and Aedes albopictus. DV infection remains the most prevalent mosquito-borne viral disease and the geographical regions at risk are continually growing due to globalisation and climate change. It is estimated that 100 million cases of dengue infection occur worldwide each year with 2.5 billion people at risk. Till now, no effective treatment and vaccine are available for DV infection."
  },
  {
    para:"Studies have demonstrated that the host immunological mechanism could play a key role in the manifestation of dengue infection. Up-regulation of proinflammatory cytokines in immune cells during dengue virus infection could lead to increased vascular permeability and leakage."
  },
  {
    para:" Toll-like receptors (TLRs) are pathogen recognition receptors (PRRs). PRRs are a group of receptors that play a key role in immune surveillance. Pathogen recognition receptors are important as they alert the immune system of the presence of foreign microbes by recognizing pathogen-associated molecular patterns (PAMPs) and activating the immune system upon binding to PAMPs."
  },
  {
    para:" A study by Cheng J found that the dengue virus infection activated and up-regulated TLR2 and TLR6 of human PBMC and DV NS1 protein was shown to be the viral protein responsible. TLR6 was found to be up-regulated by PBMC on day 3 post-infection. As TLR2 is partner of TLR6, its expression by PBMC during dengue virus infection was also investigated. TLR6, TLR2 was found to be up-regulated by PBMC on day 3 post-infection. The mechanism of TLR2 regulation has not been fully elucidated. It was reported that chromatin remodeling involving DNase I and restriction enzyme occurs at TLR2 promoter region following infection. This remodeling of chromatin increases accessibility of transcription factors resulting in greater transcription of TLR2. In addition, two pathways were found to be important for TLR2 regulation. IKKβ-IκBα-dependent NFκB pathway activation and MKK3/6-p38α/β pathway inhibition are essential for TLR2 expression. The production of cytokines could be the cause of the development of dengue hemorrhagic fever as cytokines were found to play important roles in several viral hemorrhagic fevers."
  },
  {
    para:"Furthermore, cytokines were found to have prognostic value in DV infection in other studies. All these findings suggest that a possible treatment for dengue would be to control the proinflammatory cytokine production during DV infection. (Chen J et al.,2015). "
  },
  {
    para:" Tomaralimab is a novel immunomodulator of TLR2. Given the codependency of TLR2 and TLR6 suggestion as to the therapeutic effects of Tomaralimab in the management of Dengue provides an avenue of important medical need."
  }
  ]

export default function Dengue() {
  return (
    <div className='md:py-[30px]   
    px-[20px] py-[20px]
    sm:px-[80px] 2xl:py-[80px] font-openSans'>

<h1 className='text-[36px] sm:text-[48px] font-openSans 2xl:text-6xl text-center font-bold'>TLR2 and Dengue</h1>

{Para.map((data,i)=>(
        <div className='xl:text-[24px] 2xl:text-3xl py-[30px] '>

          <p>{data.para}</p>

      </div>))}
      
    </div>
  )
}
