import React from 'react'
import { Link ,NavLink} from "react-router-dom";

let TEAM = [
  {
    title: "Driving Innovation in Therapeutic Development",
    desription: "At TLR Therapeutics, we have assembled a team of exceptional individuals who are dedicated to advancing the field of therapeutic development and improving patient outcomes. Our team consists of experts with diverse backgrounds and areas of expertise, all united by a common goal: to bring innovative treatments to those in need. Let us introduce you to the members of our esteemed team:",
    para:"",
  },
  {
    title: "Stephen J. McCormack, PhD - Executive Chairman ",
    desription: "Dr. Stephen J. McCormack serves as the Executive Chairman of TLR Therapeutics. With extensive experience in the biotechnology industry, Dr. McCormack brings visionary leadership and strategic guidance to the company. His expertise in translating scientific discoveries into therapeutic breakthroughs has been instrumental in driving our research and development efforts.",
    para:"",
  },
  
  {
    title: "Mona Sharma - Regulatory Consultant",
    desription: "Mona Sharma, our regulatory consultant, has an in-depth understanding of the complex regulatory landscape in the pharmaceutical industry. With her vast experience in navigating regulatory requirements, Ms. Sharma plays a vital role in guiding our development strategies and ensuring compliance with regulatory authorities. Her expertise accelerates our progress towards obtaining necessary approvals for our therapies.",
    para:"",
  },
  {
    title: "Alexis Poché, MS - Project Manager",
    desription: "As our dedicated project manager, Alexis Poché oversees the coordination and execution of our research and development projects. With her strong organizational skills and attention to detail, Ms. Poché ensures seamless collaboration among various teams and timely completion of milestones. Her efficient project management keeps us on track to meet our development goals.",
    para:"",
  },
  {
    title: "Kamal Egodage - CMC Consultant",
    desription: "Kamal Egodage serves as our CMC (Chemistry, Manufacturing, and Controls) consultant. With extensive knowledge of pharmaceutical manufacturing processes and quality control, Mr. Egodage plays a vital role in ensuring the production of high-quality drug substance and drug product for our clinical trials. His expertise guarantees that our therapies meet the stringent standards required for patient safety.",
    para:"",
  },
  {
    title: "Clinical advisors: ",
    desription: "Together, our team at TLR Therapeutics and our esteemed collaborators at MD Anderson Cancer Center are dedicated to pushing the boundaries of therapeutic development. Through our collective expertise and unwavering commitment, we aim to bring new hope and improved treatment options to patients worldwide.",
    para:"",
  },
];


export default function About() {
  return (
    <div className=' md:py-[30px] font-openSans  
    px-[20px] py-[20px]
    sm:px-[80px]  2xl:py-[80px] overflow-x-hidden'>

      {/* --------------------------------------------About PAge------------------------------------------- */}

        

        <div className='grid   lg:grid-cols-2 xl:grid-cols-2 sm:py-0 xl:pt-[60px]'>

            <div className='flex flex-col justify-center xl:justify-items-start '>
                <h1 className='text-[36px] sm:text-[48px] 2xl:text-6xl font-bold pb-[20px]'>About Us</h1>
                <p className='lg:px-[0px] xl:px-[0px] mr-[40px]  xl:text-[20px] 2xl:text-3xl pb-[10px]'>
                We believe that every individual deserves access to the latest advancements in medical science. We are committed to conducting rigorous and ethical clinical. 
                </p>
                <p className='lg:px-[0px] xl:px-[0px] mr-[40px] lg:py-[10px] 2xl:py-[30px]  xl:text-[20px] 2xl:text-3xl'>
                Our team of experienced researchers, physicians, and healthcare professionals are driven by a shared mission: to revolutionize the way diseases are prevented, diagnosed, and treated.</p>
            </div>

            <div className='flex items-center justify-center sm:justify-center md:justify-center xl:justify-start -order-1 lg:order-1 '>
                <img className='lg:w-[630px] lg:h-full w-[380px] sm:w-[600px]  2xl:w-[1200px] ' src="./images/Rectangle 11.webp" alt="" />
            </div>
        </div>

        {/*-------------------------------------------- TEAM PAGE-------------------------------------------------------- */}
        <h1 className='text-[36px] sm:text-[48px] 2xl:text-6xl font-bold pb-[40px]'>Team</h1>

     
              <div className='flex flex-col lg:flex-row align-bottom gap-5'>

                  <div className='flex  justify-center lg:justify-start '>
                    <img  src="./images/About3.svg" alt="" className='min-w-[200px]' style={{height:"auto"}}/>
                  </div>

                  <div className='flex flex-col justify-center flex-wrap'>
                      <h1 className="md:text-2xl xl:text-[30px] 2xl:text-4xl font-bold lg:pb-[10px]">Stephen J. McCormack, PhD - Executive Chairman</h1>
                      <p className="xl:text-[24px] 2xl:text-3xl pb-[] md:pb-[30px]"><Link to="https://scholar.google.com/citations?user=b-t93NsAAAAJ&hl=en" target='_blank' className='text-blue-900 '>Dr. McCormack </Link> is a serial entrepreneur, executive and investor in the biotechnology and medical device field. Dr. McCormack co-founded and operated many companies including:  MNKD, that has developed an approved inhaler for diabetes, and other chronic medical conditions; </p>

                      
                  </div>

              </div>
              <div className='mt-[10px]'>
              <p className="xl:text-[24px] 2xl:text-3xl pb-[] md:pb-[30px]"> Neurosystec, for drug delivery through cochlear implants and treatment of tinnitus; and, CytoMx Therapeutics, NASDAQ:CTMX, currently developing antibody therapeutics.  One of Dr. McCormack’s most significant professional achievements was the European approval of a retinal implant device to restore sight to the blind at Pixium Vision, EUR:PIX.</p>
              <p className="xl:text-[24px] 2xl:text-3xl pb-[] md:pb-[30px]">For several years, McCormack was a CEO for Alfred Mann and an integral member in the family office through the Alfred Mann Institutes and Foundation. He later was a Managing Director at Global Life Science Ventures in Zug, CH. McCormack was also an Operating Partner at Titan Grove Holdings –an investment vehicle of Fir Tree Capital. He has been a managing partner at the Gorlin Companies family office and the CEO of ViCapsys, a regenerative medicine company that has developed a transplant technology to treat Type 1 Diabetes. Dr. McCormack is passionate about creating solutions for the opioid crisis and has been a longtime trustee and philanthropy council of Hazelden Betty Ford Foundation.</p>
                      <p className="xl:text-[24px] 2xl:text-3xl pb-[] md:pb-[30px]">McCormack was part of the founding senior management at the Keck Graduate Institute and has previously held academic positions at Georgetown University Medical Center, Rockefeller University, George Mason University and the University of Massachusetts Medical Center. He was previously a Senior Fellow at the Kennedy Institute of Ethics and a post-doctoral fellow at the Lombardi Cancer Center both at Georgetown University. McCormack received his PhD in virology in the laboratory of Charles E. Samuel, PhD from the Interdepartmental Biomolecular Science and Engineering program from University of California, Santa Barbara. The thesis research was based on identifying and characterizing the role of the PKR in anti-viral action through Type 1 Interferon pathway activation. He obtained a bachelor’s degree in biology from the College of the Holy Cross in Worcester, MA. </p>
                      <p className="xl:text-[24px] 2xl:text-3xl pb-[] md:pb-[30px]">His scientific contributions and patents include delivery of drugs to the eye and inner ear, medical devices for the treatment of tinnitus, allergy vaccines, and diagnostic tests for cervical cancer. Among many honors, he earned the National Cancer Institute's National Research Service Award and National Institutes of Health SPORE Fellowship while at Georgetown University. He also received the International Society for Interferon Research Award and University of California Academic Senate Award. He was previously a Senior Fellow at the Kennedy Institute of Ethics at Georgetown University and was involved in public policy issues related to commercialization and patenting in the biopharmaceutical industry.https://scholar.google.com/citations?user=b-t93NsAAAAJ&hl=en</p>
              </div>




               <div className='flex flex-col lg:flex-row align-bottom gap-5'>
                  <div className='flex  justify-center lg:justify-start'>
                    <img className=' 2xl:w-full 2xl:px-16 min-w-[200px] ' src="./images/About2.svg" alt="" style={{height:"auto" }}/>
                  </div>

                  <div className='flex flex-col justify-center flex-wrap'>

                    <h1 className="md:text-2xl xl:text-[30px] 2xl:text-4xl font-bold lg:pb-[10px]"> Prof. Seung-Jae Lee - Director</h1>
                    <p className="xl:text-[24px] 2xl:text-3xl pb-[30px]">Prof. Seung-Jae Lee is one of the leading scientists in the field of neurodegenerative diseases with special emphasis on Parkinson disease and related movement disorders and dementia. His research is centered around the role of protein aggregation in the pathogenesis of neurodegenerative diseases. 
                    </p>
                  </div>

              </div>
              <div>
              <p className="xl:text-[24px] 2xl:text-3xl pb-[30px]"> He has been developing and analyzing the cellular and animal models of protein aggregation and studied basic principles of biological processes leading to protein aggregation and the clearance of the aggregates. Recently, his research is focused on the mechanism of progression of protein aggregate pathology and on the role of protein aggregates in neuroinflammation. His research now also includes the development of novel therapeutic drugs for Parkinson disease. </p>
                    <p className="xl:text-[24px] 2xl:text-3xl pb-[30px]">
                    Prof. Lee started his research group at the Parkinson’s Institute in Sunnyvale, CA in 2000, where he developed a research program for the study of pathophysiology of alpha-synuclein. He then moved to Konkuk University in Seoul, Korea in 2006, where he continued his work on alpha-synuclein and performed a series of studies on alpha-synuclein secretion and its roles in aggregate propagation and neuroinflammation. In 2015, Prof. Lee moved to Seoul National University in Seoul, Korea. While he continues the work on alpha-synuclein and Lewy body diseases, Dr. Lee is currently expanding the spectrum of his research program that now includes other neurodegenerative diseases-linked proteins, such tau, huntingtin, and TDP-43.
                    </p>
                    <p className="xl:text-[24px] 2xl:text-3xl pb-[30px]">
                    Prof. Lee received undergraduate education at Seoul National University and graduate training at POSTECH. He performed postdoctoral works at National Institutes of Health in Bethesda, MD, and then at Harvard Medical School in Boston, MA. 
                     </p>
                    <p className="xl:text-[24px] 2xl:text-3xl pb-[30px]">
                    Prof. Lee has been an active member of several domestic and international scientific societies. He is currently serving as a senior editor of Brain Research and an associate editor for Experimental & Molecular Medicine. 
                    </p> 
              </div>

               <div className='flex flex-col lg:flex-row align-bottom gap-5'>
                <div className='flex  justify-center lg:justify-start'> 
                  <img className=' 2xl:w-full 2xl:px-16 min-w-[200px] ' src="./images/About1.svg" alt="" style={{height:"auto"}}  />
                </div>

                  <div className='flex flex-col justify-center flex-wrap'>
                  <h1 className="md:text-2xl xl:text-[30px] 2xl:text-4xl font-bold lg:pb-[10px]">Minsup Lee - Director</h1>
                    <p className='xl:text-[24px] 2xl:text-3xl '>
                    Senior Managing Director, Head of Investment Banking Division, KB Investment and Securities, Seoul, Korea.  
                    Lead Investment Banking Division; M&amp;A, Acquisition financing, PEF, ECM- The Bell: IB Award 2010, The Best Innovative M&amp;A House (January 19, 2010) 
                    </p>
                   
                  </div>
              </div>
              <div className='mt-[10px]'>
              <p className='xl:text-[24px] 2xl:text-3xl pb-[15px]'>
                    - The Asia Economy Daily: Asia Capital Investment Award, The Best M&amp;A House (May 26, 2009) 
                    </p>
                    <p className='xl:text-[24px] 2xl:text-3xl pb-[15px]'>
                    - The Bell: IB Award 2009, The Best M&amp;A House (May 25, 2009) - The Herald Business: Capital Market Award, Special Mention Award (March 31, 2009) 
                    </p>
                    <p className='xl:text-[24px] 2xl:text-3xl pb-[15px]'>- Directed overall marketing, sales, and execution of investment banking business; M&amp;A, capital market deals, PEF and principal investment opportunities 2006-2008 Senior Vice President, Head of Corporate Finance Korea, Lehman Brothers, Seoul, Korea. Lead Investment Banking coverage practice in Korea 
                    </p>
                    <p className='xl:text-[24px] 2xl:text-3xl pb-[15px]'>
                    - Covered major key accounts in Korea including TMT, Industrials, FIG, and 
                    Energy/Chemical, Consumer &amp; Retail clients - Focused on M&amp;A deals with joint pursuit of capital market and principal investment opportunities. 

                    </p>
              </div>
        

        {/* --------------------------------------------Paragraphs------------------------------------------------------------*/}
      
      <h1 className='text-[36px] sm:text-[48px] 2xl:text-6xl font-bold pt-[20px]'>TLR Therapeutics Team</h1>

    
      {TEAM.map((data,i)=>(
        <div className='xl:text-[24px] 2xl:text-3xl pb-[30px]'>

             <h1 className='font-bold text-[32px] pt-2'>{data.title}</h1>
             <p className=''>{data.desription}</p>
             <p>{data.para}</p>

      </div>))}

      {/* <p className='xl:text-xl 2xl:text-3xl'>Together, our team at TLR Therapeutics and our esteemed collaborators at MD Anderson Cancer Center are dedicated to pushing the boundaries of therapeutic development. Through our collective expertise and unwavering commitment, we aim to bring new hope and improved treatment options to patients worldwide.</p> */}

    </div>
  )
}



