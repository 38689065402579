import React from 'react'

const Para=[{
    para:"In humans, TLRs have a broad expression in various tissues, with the most diversity in locations involved in immune function, such as the spleen and peripheral blood, and locations in constant contact with microbes, such as the lung and the gastroenterological tract ( Zarember KA , et al 2002). Other locations expressing TLRs include the female reproductive tract, urinary tract, skin, neural system, and vascular system (Ridnour LA , et al 2013, Fellner L et al 2013, Ayari C et al, 2010)."
  },
  {
    para:"Specific to cell types, TLRs can typically be found in immune cells, such as DCs, monocytes, macrophages, granulocytes, NK cells, mast cells, and lymphocytes. Other non-immune cells also express TLRs, including endothelial cells, epithelial cells, fibroblasts, glial cells, astrocytes, keratinocytes, vascular smooth muscle cells, and sperm cells (Gnjatic S et al 2010, Bayraktar R et al 2019, Patra MC et al 2019, Ridnour LA , et al 2013, Fellner L et al 2013, Ayari C et al, 2010, Kadowaki N et al 2002. Fujita Y et al 2011)."
  },
  {
    para:"Recent evidence has shown that TLRs are expressed in diverse tumor cell populations (including tumor stem cells) together with cancer-associated fibroblasts, tumor-associated macrophages, myeloid-derived suppressor cells (MDSCs), regulatory T cells (Tregs), and adipocytes in the TME, participating in both promotion and inhibition in tumor growth."
  },
  {
    para:"During the process of malignant transformation, the level of TLR expression tend to elevate in transformed cells (including tumor cells); meanwhile, the expression of TLR2, 4, 5, which is normally on cell membrane, increases in cytosol in a diffused manner. The expression patterns of TLRs and the downstream molecules are varied and regulated by multiple factors. Understanding these factors is vital for better exploitation in pre-clinical/translational research in TLR-targeted anti-tumor therapies."
  },
 
  {
    para:"Ligands are another major factor affecting TLR expression. A specific TLR agonist may influence the expression of the TLR that it is ligating to and other TLRs as well. For example, the TLR4 agonist LPS elevated TLR1-8 expression in THP-1 cells, not just TLR4. In addition, different ligands may differentially regulate the expression of a particular TLR. While TLR3 expression is upregulated by LPS, it is down-regulated by synthetic bacterial lipopeptide - a TLR2 agonist. Hence, cell localization related to potential ligand accessibility can impact TLR expression. As the case with TLR2 expression in B cells, only a restricted portion of peripheral blood B cells express TLR2, but different developmental stages of tonsillar B cells all express TLR2. Cytokines are also engaged in the regulation of TLR expression. Many cytokines have been reported to increase TLRs expression level, including IL-6 and IFN-γ.  "
  },
  {
    para:"TLR-targeted therapies exert anti-tumor effects mainly by exploiting the potential of TLRs in the enhancement of both innate and adaptive immunity, and the induction of apoptosis in TLR-expressing tumor cells.  In tumors, TLR2 has been found to enhance T cell immunity and suppress the accumulation of immunosuppressive T regulatory cells (Tregs) and MDSCs. TLR2 also induces the anti-tumor M1-like macrophage polarization. The immune stimulating potential of TLR2 has been utilized in both basic and clinical therapeutic research. Recently, there has been pre-clinical data demonstrating tumor inhibition effects by different TLR2, including triacylated lipopeptides, synthetic chemical compounds, glucomannan polysaccharide, naturally extracted compound, and inactivated virus. In those studies, TLR2Ls are either investigated alone or in combination with tumor vaccines, immune checkpoint inhibition (ICB), chemotherapy, photodynamic therapy(PDT), or adoptive cell transfusion (ACT). In general, enhanced immune activation and suppressed tumor cell viability were observed in these studies. The number of clinical trials with sole TLR2 agonism in tumor immunotherapy is limited. (Yang Y et al, 2022)"
  },
  {
    para:"Given the significant role of TLR2 in solid tumor biology, it has emerged as a potential target for cancer therapy. Several preclinical studies have investigated TLR2 agonists or antagonists as adjuvants to enhance anti-tumor immunity or inhibit tumor growth, respectively. However, the clinical translation of these findings is still in its early stages, and more research is needed to understand the complexities of TLR2 signaling in various cancer types."
  }
 
  ]

export default function Tumor() {
  return (
    <div className='md:py-[30px]   
    px-[20px] py-[20px]
    sm:px-[80px] 2xl:py-[80px] font-openSans'>
      
      <h1 className='text-[36px] sm:text-[48px] font-openSans 2xl:text-6xl text-center font-bold'>TLR2 and Solid Tumors</h1>

      {Para.map((data,i)=>(
        <div className='xl:text-[24px] 2xl:text-3xl py-[30px] '>

          <p>{data.para}</p>

      </div>))}
    
    <div className='xl:text-[24px]'>
      <p className='font-bold'>Conclusion:</p>
      <p>The available evidence suggests that TLR2 plays a multifaceted role in solid tumor biology, affecting tumor growth, invasion, metastasis, and the immune response. Its activation can have both pro-tumorigenic and anti-tumorigenic effects, making it a challenging target for cancer therapy. Further investigation into the context-specific roles of TLR2 in different solid tumors will be crucial for the development of targeted and effective therapeutic strategies.</p>
    </div>

    </div>
  )
}


