import React from 'react'

export default function Breast() {
  return (
    <div>
      <div className=' md:py-[30px]   
    px-[20px] py-[20px]
    sm:px-[80px] 2xl:py-[80px] font-openSans '>

    <h1 className='text-[36px] sm:text-[48px] font-openSans 2xl:text-6xl text-center pb-5 font-bold'> Breast Cancer</h1>

     <div className='flex items-center justify-center '>
        <img src="./images/Breast.svg" alt="" />
      </div>
      <div className=' 2xl:px-[350px] lg:px-[130px] xl:px-[300px] sm:px-[40px] px-[30px]  text-[#454343] text-[12px]'>
          <p>Kaplan-Meier (KM) overall survival curves constructed with the expression of TLR2. (A) The KM curves for the total study population. (B) The KM curves for patients received surgery. (C) The KM curves for patients underwent endocrine therapy. (D) The KM disease free survival curves constructed with the expression of TLR2.</p>
        </div>

    </div>
    </div>
  )
}
