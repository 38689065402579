import React from 'react'
import { Link, NavLink } from "react-router-dom";

export default function Content() {
  return (
    <div>
        <ul className=" text-black ml-[-35px]">
        <li className="pt-[15px] " >
            <NavLink to="/MDS">TLR2 and MDS</NavLink>
          </li>
          <li className="pt-[15px]" >
            <NavLink to="/covid">TLR2 and Covid 19</NavLink>
          </li>
          <li className="pt-[15px] " >
            <NavLink to="/dengue">TLR2 and Dengue</NavLink>
          </li>
          <li className="pt-[15px] " >
            <NavLink to="/tumor">TLR2 and Solid Tumors</NavLink>
          </li>
        </ul>
    </div>
  )
}
