import React from 'react'

let LATEST_UPDATES = [
  {
    imageUrl:"./images/SME.jpg",
    desription:"TLR gains SME EU Status",
  },
  {
    imageUrl: "./images/Rectangle 89.jpg",
    desription: "TLR meets with FDA on Type C meeting on the path for MDS development. Indicating plans for Phase 1 study in MDS population.",
  },
  {
    imageUrl: "./images/88.jpg",
    desription: "In addition to its application in MDS, Tomaralimab shows promise in the treatment of solid tumors, including gastric cancer. Ongoing research and development aim to expand its reach and improve the quality of life for patients facing these challenging diseases. Joining the clinical trial for Tomaralimab offers an opportunity to contribute to the development of a breakthrough treatment and potentially transform the lives of patients with MDS and solid tumors.",
  },
  {
    imageUrl: "./images/Rectangle 88.jpg",
    desription: "To ensure high standards of safety and quality, GMP-grade drug substance, drug product, and placebo have been produced and are available for use in the clinical trials. This commitment to quality ensures the accurate evaluation of Tomaralimab's therapeutic potential and the well-being of trial participants.",
  },
  {
    imageUrl: "./images/Rectangle 87.jpg",
    desription: "The Phase 2b/Phase 3 clinical trial of Tomaralimab aims to further evaluate its safety, efficacy, and potential benefits in a larger patient population. By participating in this trial, patients and healthcare professionals contribute to advancing medical knowledge and potentially improving patient outcomes.",
  },
  {
    imageUrl: "./images/Rectangle 86.jpg",
    desription: "MDS is a complex group of hematologic malignancies characterized by peripheral blood cytopenia and an increased risk of transforming into acute myelogenous leukemia (AML). It affects fewer than 200,000 patients in the United States, making it eligible for orphan drug status.",
  },
  {
    imageUrl: "./images/Rectangle 85.jpg",
    desription: "Tomaralimab, also known as TLR-2982/OPN-305, is an innovative therapeutic monoclonal antibody (mAb) with potential in treating various cancers, including solid tumors and Myelodysplastic Syndrome (MDS). It has shown promising early clinical results and has been granted orphan drug designation by the FDA for MDS treatment.",
  },
];


export default function Home() {
  return (
    <div className=' md:py-[30px]   
    px-[20px] py-[20px]
    sm:px-[80px] 2xl:py-[80px] '>
        <h1 className='text-[36px] sm:text-[48px] font-openSans 2xl:text-6xl font-bold'>Latest Updates</h1>

      {LATEST_UPDATES.map((data,i)=>(
        <div className='grid py-[20px]  lg:grid-cols-2 xl:grid-cols-3 sm:py-0 md:py-[30px] 2xl:py-[80px]'>

              <div className='flex items-center justify-center sm:justify-center md:justify-center xl:justify-start '>
                  <img className='lg:w-[500px] w-[380px] sm:w-[600px] xl:w-full 2xl:w-[1200px] py-6 ' src={data.imageUrl} alt="" />
              </div>

              <div className='flex flex-col justify-center xl:justify-items-start xl:col-span-2'>
                  <p className='pl-[10px]   lg:pl-[40px]  md:text-[18px] lg:text-[18px] xl:text-[24px] 2xl:text-3xl'>
                {data.desription}
                  </p>
              </div>

        </div>))}

       
    </div>
  )
}
