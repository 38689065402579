import React from 'react'

export default function Gastric() {
  return (
    <div className=' md:py-[30px]   
    px-[20px] py-[20px]
    sm:px-[80px] 2xl:py-[80px] font-openSans'>

    <h1 className='text-[36px] sm:text-[48px] font-openSans 2xl:text-6xl text-center pb-5 font-bold'>Gastric Cancer  </h1>

     <div className='flex items-center justify-center'>
        <img src="./images/Gastric.svg" alt="" />
      </div>
      <div className='  2xl:px-[350px] lg:px-[130px] xl:px-[290px] sm:px-[40px] px-[30px]  text-[#454343] text-[12px] '>
          <p className='xl:ml-[40px]'>Receiver operating curve (ROC) analysis to determine diagnostic accuracy in the differenti- ation of patients with gastric cancer.</p>
        </div>

    </div>
  )
}
