import React from "react";
import "./Pipeline.css";
import { Link } from "react-router-dom";

export default function Pipeline() {
  return (
    <div
      className=" md:py-[30px]   
    px-[20px] py-[20px]
    sm:px-[70px] 2xl:py-[80px] font-openSans"
    >
      <div className="flex justify-center items-center break-all">
        <h1 className="flex font-bold text-[25px] sm:text-[30px] md:text-[48px] 2xl:text-6xl">Medicine:</h1>
        <h1 className="text-[25px] sm:text-[30px] md:text-[48px] 2xl:text-6xl pl-1">Tomaralimab</h1>
      </div>

      <div className="flex   justify-start sm:justify-center text-[7px] sm:text-[11px] font-bold">

        <div className="sm:mt-[20px] sm:w-[130px] w-[130px] Links">

          <Link to="/dengue"><h1 className="Dengue flex mt-[10px] justify-end">Dengue</h1></Link>
          <Link to="/covid"><h1 className="Covid flex justify-end mt-[10px]  sm:mt-[16px] ">Covid 19 Pneumonia</h1></Link>
          <Link></Link><h1 className="GC flex justify-end  mt-[12px]  sm:mt-[16px]">Glioblastoma Cancer</h1>
          <Link to="/breast"><h1 className="BC flex justify-end  mt-[12px]  sm:mt-[16px]">Breast Cancer</h1></Link>
          <h1 className="EC flex justify-end  mt-[12px]  sm:mt-[16px]">Eomential Cancer</h1>
          <h1 className="OC flex justify-end  mt-[12px]  sm:mt-[16px]">Ovarian Cancer</h1>
          <Link to="/gastric"> <h1 className="GAC flex justify-end mt-[12px]  sm:mt-[16px]">Gastric Cancer</h1></Link>
          <Link to="/MDS"><h1 className="MDS flex justify-end mt-[12px]  sm:mt-[16px]">MDS</h1></Link>
          
        </div>
        
        <div className="px-5 sm:px-10">
          <div>
            <img
              className="object-contain sm:h-[300px] sm:max-w-[300px] max-w-[200px] Image "
              src="./images/pipeline.svg"
              alt=""
            />
          </div>

          <div className="flex  lg:mt-[-5px] sm:justify-center  text-[7px] sm:text-[11px] font-bold py-2 sm:ml-[-15px]">
            <h1>
              Pre-clinical
            </h1>
            <h1 className="px-1 md:px-3">
              Translational
            </h1>
            <h1 className="px-1">Phase 1</h1>
            <h1 className="px-1">Phase 2</h1>
            <h1 className="px-1">Phase 3</h1>
          </div>
        </div>
        
      </div>
      
    </div>
  );
}
