import React from 'react'

export default function Yang() {
  return (
    <div className=' md:py-[30px]   
    px-[20px] py-[20px]
    sm:px-[80px] 2xl:py-[80px] font-openSans '>

    <h1 className='text-[36px] sm:text-[48px] font-openSans 2xl:text-6xl text-center pb-5 font-bold'> Yang et al 2022</h1>

     <div className='flex items-center justify-center '>
        <img src="./images/Yang.svg" alt="" />
      </div>
      <div className=' 2xl:px-[350px] lg:px-[150px] xl:px-[330px] sm:px-[40px] px-[30px]  text-[#454343] text-[12px]'>
          <p>TLR ligands and signaling pathways Physiologically, TLRs are expressed on the cell membrane (TLR 1 2 4, 5, 6, 10) or the endosome (TLR3, 7, 8, 9). TLRs recognize a wide range of pathogen-associated molecular patterns (PAMPs). Upon ligation, dimerized TLRs can activate either MyD88 or TRIF pathways TLR4 can activate MyD88 (with adaptor protein TIRAP) and TRIF (with adaptor protein TRAM) pathways: TLR3 activates TRIF alone, and TLR1/2, TLR2/6 TLR2/10, TLR5 as well as TLR7-9 activate MyD88 alone all without the need of an adaptor protein. As a result, type IFNs are induced via IRF3 and IRF7 activation and inflammatory cytokines via NF-kB. MAPKs, and IRFS stimulation, which thus initiates a wide range of inflammatory and immune activities, giving the basis for therapeutic exploration on TLRs in tumor therapies.</p>
        </div>

    </div>
  )
}
