import React from 'react'

export default function Contact() {
  return (
    <div className=' md:py-[30px]   
    px-[20px] py-[30px]
    sm:px-[60px] 2xl:py-[60px] font-openSans 
    xl:py-[60px] xl:px-[188px]'>
      
      <h1 className='text-[36px] sm:text-[48px] font-openSans 2xl:text-6xl pb-[10px] sm:pb-[40px]  font-bold'>Contact Us</h1>

      <div className='sm:text-[32px] font-normal'>
      <p>TLR Therapeutics, Inc.</p>
      <p>500 La Terraza Boulevard</p>
      <p>Suite 150</p>
      <p>Escondido, CA 92025</p>
      
      <p className='mt-[15px] sm:mt-[30px]'> Contact at tlrtx period com | tlrtx.com </p>
      
      </div>

    </div>
  )
}

