import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Covid from "./components/Covid";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dengue from "./components/Dengue";
import Tumor from "./components/Tumor";
import Published from "./components/Published";
import ScrollToTop from "./components/ScrollToTop";
import Pipeline from "./components/Pipeline";
import Yang from "./components/Yang";
import MDS from "./components/MDS";
import Gastric from "./components/Gastric";
import Breast from "./components/Breast";
import Hehe from "./components/Hehe";

function App() {
  return (
    <div className="">
      <ScrollToTop>
        <Navbar />

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/covid" element={<Covid />} />
          <Route path="/tumor" element={<Tumor />} />
          <Route path="/dengue" element={<Dengue />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/publish" element={<Published />} />
          <Route path="/pipeline" element={<Pipeline />} />
          <Route path="/yang" element={<Yang />} />
          <Route path="/MDS" element={<MDS />} />
          <Route path="/gastric" element={<Gastric />} />
          <Route path="/breast" element={<Breast />} />
        </Routes>

        <Footer />
      </ScrollToTop>
      
    </div>
  );
}

export default App;
