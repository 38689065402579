import React, { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";
import "./nav.css";
import { BiChevronDown,BiChevronUp } from "react-icons/bi";
import Content from "./Content";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Hehe() {
  const [dropdown, setDropDown] = useState(false);
  const [nav, setNav] = useState(true);
 

  const handleNav = () => {
    setNav(!nav);
  };

  const handleDropDown = () => {
    setDropDown(!dropdown);
  };

 

  return (
    <div
      className=" shadow-md flex justify-between items-center   py-[14px] 
   px-[20px]
   sm:px-[80px]
   md:px-[80px]
   lg:px-[80px]"
    >
      <Link to="/">
        <div className="">
          <img
            className="w-[200px] md:w-[200px] lg:w-[135px] xl:w-[300px] 2xl:w-[400px]  "
            src="./images/Logo 2.svg"
            alt=""
          />
        </div>
      </Link>
      <div className="text-[12px] md:text-[11px] xl:text-[16px] 2xl:text-2xl">
        <ul className="hidden lg:flex ">
          <li className="font-openSans pl-[20px] font-bold ">
            <NavLink to="/" className="hover:text-[#6DC9E8]">
              Home
            </NavLink>
          </li>

          <li className="font-openSans pl-[20px] font-bold ">
            <NavLink to="/about" className="hover:text-[#6DC9E8]">
              About Us
            </NavLink>
          </li>

          <li className="font-openSans pl-[20px] font-bold">
            <Menu as="div" className="relative inline-block text-left">
              <div onClick={handleDropDown}>
                <Menu.Button className="inline-flex   rounded-md   font-openSans text-gray-900 font-bold  hover:text-[#6DC9E8]">
                  TLR 2
                  <div
                    className="hover:text-[#6DC9E8]"
                    onClick={handleDropDown}
                  >
                    {!dropdown ? (
                      
                      <BiChevronDown className="ml-[8px]  xl:mt-[0px] mx-1 font-bold text-[16px] xl:text-2xl" />):
                  
                    (<BiChevronUp className="ml-[8px]  mx-1 font-bold text-[16px] xl:text-2xl" />
                    )}
                  </div>
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item
                      className="hover:text-[#6DC9E8]"
                      onClick={handleDropDown}
                    >
                      {({ active }) => (
                        <NavLink
                          to="/MDS"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          TLR2 and MDS
                        </NavLink>
                      )}
                    </Menu.Item>

                    <Menu.Item
                      className="hover:text-[#6DC9E8]"
                      onClick={handleDropDown}
                    >
                      {({ active }) => (
                        <NavLink
                          to="/covid"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          TLR2 and Covid 19
                        </NavLink>
                      )}
                    </Menu.Item>
                    <Menu.Item
                      className="hover:text-[#6DC9E8]"
                      onClick={handleDropDown}
                    >
                      {({ active }) => (
                        <NavLink
                          to="/dengue"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          TLR2 and Dengue
                        </NavLink>
                      )}
                    </Menu.Item>
                    <Menu.Item
                      className="hover:text-[#6DC9E8]"
                      onClick={handleDropDown}
                    >
                      {({ active }) => (
                        <NavLink
                          to="/tumor"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          TLR2 and Solid Tumors
                        </NavLink>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </li>

          <li className="font-openSans pl-[20px] font-bold ">
            <NavLink to="/pipeline" className="hover:text-[#6DC9E8]">
              Pipeline
            </NavLink>
          </li>

          <li className="font-openSans pl-[20px] font-bold ">
            <NavLink to="/publish" className="hover:text-[#6DC9E8]">
              Published Research
            </NavLink>
          </li>

          <li className=" font-openSans pl-[20px] font-bold hover:text-[#6DC9E8]">
            <NavLink to="/contact">
              Contact Us
           </NavLink>
            
          </li>
         
        </ul>
      </div>

      {/* ----------------------------Mobile Phone Navbar Functionality---------------------------------------- */}

      <div className="lg:hidden md:text-2xl" onClick={handleNav}>
        {!nav ? <AiOutlineMenu /> : <AiOutlineMenu />}
      </div>

      <div
        className={
          !nav
            ? "fixed top-0 left-0 bg-white w-[100%] h-full border-r  border-r-gray-900 ease-in-out duration-500 overflow-x-auto"
            : "fixed left-[-100%]"
        }
      >
        <div className="flex items-center justify-start py-4 px-[10px]">
          <img
            className="w-[200px] sm:w-[300px] md:w-[300px] "
            src="./images/Logo 2.svg"
            alt=""
          />
        </div>
        <div
          className="flex justify-end mt-[-37px] sm:mt-[-43px] px-[10px] "
          onClick={handleNav}
        >
          <AiOutlineClose className="sm:text-xl" />
        </div>
        <ul className=" p-4 text-black">
          <li className="p-4 border-b border-gray-600" onClick={handleNav}>
            <NavLink to="/">Home</NavLink>
          </li>
          <li className="p-4 border-b border-gray-600" onClick={handleNav}>
            <NavLink to="/about">About us</NavLink>
          </li>
          <li className="flex p-4 border-b border-gray-600">
            TLR 2
            <div
                    className="hover:text-[#6DC9E8]"
                    onClick={handleDropDown}
                  >
                    {!dropdown ? (
                      
                      <BiChevronDown className="ml-[8px]  xl:mt-[0px] mx-1 font-bold text-[20px] xl:text-2xl mt-[3px]" />):
                  
                    (<BiChevronUp className="ml-[8px]  mx-1 font-bold text-[20px] xl:text-2xl mt-[3px]" />
                    )}
                    {dropdown && <ul className=" text-black ml-[-38px]">
                        <li className="mt-[15px]"  onClick={handleNav} >
                            <NavLink to="/MDS">TLR2 and MDS</NavLink>
                        </li>
                        <li className="mt-[15px]" onClick={handleNav} >
                            <NavLink to="/covid">TLR2 and Covid 19</NavLink>
                        </li>
                        <li className="mt-[15px]" onClick={handleNav} >
                            <NavLink to="/dengue">TLR2 and Dengue</NavLink>
                        </li>
                        <li className="mt-[15px]" onClick={handleNav} >
                            <NavLink to="/tumor">TLR2 and Solid Tumors</NavLink>
                        </li>
                        </ul>}
            </div>
          </li>
          <li className="p-4 border-b border-gray-600" onClick={handleNav}>
            <NavLink to="/pipeline">Pipeline</NavLink>
          </li>
          <li className="p-4 border-b border-gray-600" onClick={handleNav}>
            <NavLink to="/publish">Published Research</NavLink>
          </li>
          <li className="p-4 border-b border-gray-600" onClick={handleNav}>
            <NavLink to="/contact">Contact Us</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}



