import React from 'react'
import { Link } from "react-router-dom";


export default function Footer() {
  return (
    <div className='bg-[#383B67] lg:w-full lg:h-[390px] font-openSans '>
      
        <div className='grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4'>

            <div className=' lg:py-[52px] py-[52px]'>
                    <div className='flex justify-center'>
                     <Link to="/"><img className=' lg:w-[145px] lg:h-[78px] items-center ' src="./images/LogoFooter.svg" alt="" /></Link> 
                    </div>
                    <div className='flex justify-center py-[10px] lg:py-[19px]  lg:text-[24px] text-white font-bold'>
                        Follow Us
                    </div>
                    {/* <div className='py-[8px] lg:py-[5px] px-[72px] lg:px-[48px] xl:px-16 '>
                        <ul className='grid grid-cols-4 ' >

                        <div className='flex justify-center '>
                            <Link to="/"> <li><img className='' src="./images/twitter.svg" alt="" /></li></Link>
                        </div>

                        <div className='flex justify-center '>
                            <Link to="/"> <li><img className='' src="./images/facebook.svg" alt="" /></li></Link>
                        </div>

                        <div className='flex justify-center 2xl:justify-center'>
                            <Link to="/"> <li><img className='' src="./images/instagram.svg" alt="" /></li></Link>
                        </div>

                        <div className='flex justify-center'>
                            <Link><li><img className='' src="./images/pinterest.svg" alt="" /></li></Link> 
                        </div>
                        </ul>
                    </div> */}
                     <div className='flex justify-center '>
                        <ul>
                            <Link to="https://www.linkedin.com/company/tlr-therapeutics/?viewAsMember=true" target="_blank"> <li><img className='' src="./images/In.svg" alt="" /></li></Link>
                        </ul>
                        </div>
                    
            </div>

            <div className='sm:py-[52px] lg:py-[34px]  text-white  '>
                <div className='px-[40px] lg:px-[60px]'>
                        <h1 className='lg:text-[24px] font-bold '>Explore </h1>
                        
                        <div className='grid text-[16px] py-[18px]'>
                          <Link to="/"         className='w-[75px]'>  <p className='py-[5px] hover:text-[#6DC9E8] '>→ Home</p>            </Link>
                          <Link to="/about"    className='w-[89px]'>  <p className='py-[5px] hover:text-[#6DC9E8] '>→ About us</p>         </Link>
                          <Link to="/publish"  className='xl:w-[167px]'> <p className='py-[5px] hover:text-[#6DC9E8] '>→ Published Research</p></Link>
                          <Link to="/pipeline" className='w-[80px]'>  <p className='py-[5px] hover:text-[#6DC9E8]'>→ Pipeline</p>           </Link> 
                          <Link to="/contact"  className='w-[103px]'> <p className='py-[5px] hover:text-[#6DC9E8]'>→ Contact Us</p>        </Link>  
                          {/* <Link to="/"> <p className='py-[5px] hover:text-[#6DC9E8]'>→ Media</p></Link> */}
                        </div>
                        
                </div>
            </div>

            <div className='lg:py-[34px]  text-white  '>
                <div className='px-[40px] sm:px-[70px] md:mx-[62px] lg:px-[0px]'>
                        <h1 className='lg:text-[24px] font-bold '>References </h1>
                        <div className='grid text-[16px] py-[18px]'>
                         <Link to="/yang" className='w-[138px]'><p className='py-[5px] hover:text-[#6DC9E8]'>→ Yang et al 2022</p></Link> 
                         <Link to="/covid"className='w-[120px]'><p className='py-[5px] hover:text-[#6DC9E8]'>→ Liu et al 2022</p></Link>
                         <Link to="/MDS"  className='xl:w-[189px]'><p className='py-[5px] hover:text-[#6DC9E8]'>→ Cassanelo G et al 2022</p></Link>
                         <Link to="/gastric"className='xl:w-[185px]'><p className='py-[5px] hover:text-[#6DC9E8]'>→ Majeswki M et al 2023</p></Link>
                         <Link to="/breast" className='w-[140px]'><p className='py-[5px] hover:text-[#6DC9E8]'>→ Wang et al 2020</p></Link>
                        </div>
                </div>
            </div>

            <div className='lg:py-[34px]  text-white  '>
                <div className='px-[40px] lg:px-[60px]'>
                        <h1 className='lg:text-[24px] font-bold '>Important Links </h1>
                        <div className='grid text-[16px] py-[18px]'>
                            
                        <Link to="/MDS"className='w-[130px]'><p className='py-[5px] hover:text-[#6DC9E8]'>→ TLR2 and MDS</p></Link>  
                        <Link to="/covid"className='w-[157px]'><p className='py-[5px] hover:text-[#6DC9E8]'>→ TLR2 and Covid19</p></Link> 
                        <Link to="/dengue"className='w-[155px]'><p className='py-[5px] hover:text-[#6DC9E8]'>→ TLR2 and Dengue</p></Link>  
                        <Link to="/tumor" className='xl:w-[187px]'><p className='py-[5px] hover:text-[#6DC9E8]'>→ TLR and Solid Tumors</p></Link>   
                        
                        </div>
                </div>
            </div>
        </div>

        <div className='mt-[-10px] px-[30px] lg:px-[188px]'>
        <hr className="h-[1px] py-[1px] border-0  dark:bg-white"/>
        </div>
        <div className='flex justify-center'>
        <p className=' text-[10px]  sm:text-[16px]  py-[10px] xl:py-[20px] text-white hover:text-[#6DC9E8]'>TLR THERAPEUTICS 2023. All Rights Reserved.</p>
        </div>

    </div>
  )
}
