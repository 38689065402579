// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active{
    
    color: #6DC9E8;
    padding-bottom: 15px;
   
}

@media only screen and (max-width: 1024px) {

    .active{
        padding-bottom: 15.5px;
        
    }
}

@media only screen and (min-width: 1024px) {

    .active{
        padding-bottom: 14.5px;
        border-bottom: 3px solid #6DC9E8;
    }
}
@media only screen and (min-width: 1280px) {

    .active{
        padding-bottom: 21.5px;
        border-bottom: 3px solid #6DC9E8;
    }
}

@media only screen and (min-width: 1536px) {

    .active{
        padding-bottom: 24.5px;
        border-bottom: 3px solid #6DC9E8;
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/nav.css"],"names":[],"mappings":"AAAA;;IAEI,cAAc;IACd,oBAAoB;;AAExB;;AAEA;;IAEI;QACI,sBAAsB;;IAE1B;AACJ;;AAEA;;IAEI;QACI,sBAAsB;QACtB,gCAAgC;IACpC;AACJ;AACA;;IAEI;QACI,sBAAsB;QACtB,gCAAgC;IACpC;AACJ;;AAEA;;IAEI;QACI,sBAAsB;QACtB,gCAAgC;IACpC;AACJ","sourcesContent":[".active{\n    \n    color: #6DC9E8;\n    padding-bottom: 15px;\n   \n}\n\n@media only screen and (max-width: 1024px) {\n\n    .active{\n        padding-bottom: 15.5px;\n        \n    }\n}\n\n@media only screen and (min-width: 1024px) {\n\n    .active{\n        padding-bottom: 14.5px;\n        border-bottom: 3px solid #6DC9E8;\n    }\n}\n@media only screen and (min-width: 1280px) {\n\n    .active{\n        padding-bottom: 21.5px;\n        border-bottom: 3px solid #6DC9E8;\n    }\n}\n\n@media only screen and (min-width: 1536px) {\n\n    .active{\n        padding-bottom: 24.5px;\n        border-bottom: 3px solid #6DC9E8;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
