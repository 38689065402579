// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 353px) {
    
    .Image{
       width: 160px !important;
       max-width: 160px !important;
    }
    .Links{
        width: 100px !important;
        max-width: 100px !important;
        
    }
      .Dengue{
        margin-top: 8px;
      }
      .Covid{
        margin-top: 7px;
      }
      .GC{
        margin-top: 7px;
      }
      .BC{
        margin-top: 8px;
      }
      .EC{
        margin-top: 6px;
      }
      .OC{
        margin-top: 6px;
      }
      .GAC{
        margin-top: 7px;
      }
      .MDS{
        margin-top: 8px;
      }
     

    }
  

 
 `, "",{"version":3,"sources":["webpack://./src/components/Pipeline.css"],"names":[],"mappings":"AAAA;;IAEI;OACG,uBAAuB;OACvB,2BAA2B;IAC9B;IACA;QACI,uBAAuB;QACvB,2BAA2B;;IAE/B;MACE;QACE,eAAe;MACjB;MACA;QACE,eAAe;MACjB;MACA;QACE,eAAe;MACjB;MACA;QACE,eAAe;MACjB;MACA;QACE,eAAe;MACjB;MACA;QACE,eAAe;MACjB;MACA;QACE,eAAe;MACjB;MACA;QACE,eAAe;MACjB;;;IAGF","sourcesContent":["@media only screen and (max-width: 353px) {\n    \n    .Image{\n       width: 160px !important;\n       max-width: 160px !important;\n    }\n    .Links{\n        width: 100px !important;\n        max-width: 100px !important;\n        \n    }\n      .Dengue{\n        margin-top: 8px;\n      }\n      .Covid{\n        margin-top: 7px;\n      }\n      .GC{\n        margin-top: 7px;\n      }\n      .BC{\n        margin-top: 8px;\n      }\n      .EC{\n        margin-top: 6px;\n      }\n      .OC{\n        margin-top: 6px;\n      }\n      .GAC{\n        margin-top: 7px;\n      }\n      .MDS{\n        margin-top: 8px;\n      }\n     \n\n    }\n  \n\n \n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
